import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/Logo.svg';
import home from '../../assets/images/Home.svg';

const NavbarComponent = ({ window }) => {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <>
        {
            window.innerWidth < 768 ?
            <Navbar>
                <div className="navigation-menu">
                    <div className="languages navigation-menu-languages">
                        <h6 className={i18n.language === 'en' ? 'active' : ''} onClick={() => i18n.changeLanguage('en')}>EN</h6>
                        <h6 className={i18n.language === 'nl' ? 'active' : ''} onClick={() => i18n.changeLanguage('nl')}>NL</h6>
                    </div>
                    <div className="navigation-menu-logo">
                        <img src={logo} className="logo" alt="247dermatologist.com"></img>
                        <FontAwesomeIcon className="icon" icon={ faBars } onClick={() => navigate('/menu')} />
                    </div>
                </div>
            </Navbar>
            :
            <Navbar>
                <img src={logo} className="navigation-logo" alt="247dermatologist.com" onClick={() => navigate('/')}></img>
                <div className="navigation-menu">
                    <ul className='navigation-menu-links'>
                        <img src={home} alt='home' onClick={() => navigate('/')} />
                        <li onClick={() => navigate('/start')}>{t('menu.start.heading')}</li>
                        <li className='pink-m' onClick={() => navigate('/about')}>{t('menu.about_us.heading')}</li>
                        <li className='black' onClick={() => navigate('/contact')}>{t('menu.contact.heading')}</li>
                    </ul>
                    <div className="languages">
                        <h6 className={i18n.language === 'en' ? 'active' : ''} onClick={() => i18n.changeLanguage('en')}>EN</h6>
                        <h6 className={i18n.language === 'nl' ? 'active' : ''} onClick={() => i18n.changeLanguage('nl')}>NL</h6>
                    </div>
                </div>
            </Navbar>
        }
    </>
  );
}

export default NavbarComponent;