import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import arrowLeft from '../../assets/images/Arrow_left.png';
import linkedin from '../../assets/images/Linkedin.svg';
import instagram from '../../assets/images/Instagram.svg';
import twitter from '../../assets/images/Twitter.svg';
import love_to_hear_en from '../../assets/images/Love_to_hear_en.png';
import love_to_hear_nl from '../../assets/images/Love_to_hear_nl.png';
import email_icon from '../../assets/images/Email.png';
import whatsapp from '../../assets/images/Whatsapp.png';

const ContactComponent = () => {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <div className='menu-page'>
        <div className='menu-page-header'>
            <div className="languages">
                <h6 className={i18n.language === 'en' ? 'active' : ''} onClick={() => i18n.changeLanguage('en')}>EN</h6>
                <h6 className={i18n.language === 'nl' ? 'active' : ''} onClick={() => i18n.changeLanguage('nl')}>NL</h6>
            </div>
            <h1>{t('menu.contact.heading')}</h1>
            <img src={arrowLeft} className="title" alt="Back" onClick={() => navigate('/menu')} />
        </div>
      
        <div className='menu-page-content'>
            <div className='menu-page-content-contact'>
              {
                  i18n.language === 'en'
                  ?
                  <img src={love_to_hear_en} className='contact-image' alt="Love to hear from you" />
                  :
                  <img src={love_to_hear_nl} className='contact-image' alt="Love to hear from you" />
              }
              <h3><Trans i18nKey="menu.contact.text1">{t('menu.contact.text1')}</Trans></h3>
              <div>
                  <span className='contact-details'>
                      <img src={email_icon} className='contact-icon' alt='Email' />
                      <h5>info@247dermatologist.com</h5>
                  </span>
                  <span className='contact-details'>
                      <img src={whatsapp} className='contact-icon' alt='Whatsapp' />
                      <h5>+297 569 65 31</h5>
                  </span>
              </div>
            </div>
        </div>


        <div className='menu-content-social-links'>
            <a href='https://www.linkedin.com/company/247dermatologist/' target="_blank" rel='noreferrer'><img src={linkedin} alt='LinkedIn' /></a>
            <a href='https://www.instagram.com/247dermatologist/' target="_blank" rel='noreferrer'><img src={instagram} alt='Instagram' /></a>
            <a href='https://twitter.com/247derm' target="_blank" rel='noreferrer'><img src={twitter} alt='Twitter' /></a> 
        </div>

        <hr className='pink-border'></hr>
    </div>
  );
}

export default ContactComponent;