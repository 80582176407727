import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import arrowLeft from '../../assets/images/Arrow_left.png'; 
import dr from '../../assets/images/Dr.png';

const TeamComponent = () => {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <div className='menu-page'>
        <div className='menu-page-header'>
            <div className="languages">
                <h6 className={i18n.language === 'en' ? 'active' : ''} onClick={() => i18n.changeLanguage('en')}>EN</h6>
                <h6 className={i18n.language === 'nl' ? 'active' : ''} onClick={() => i18n.changeLanguage('nl')}>NL</h6>
            </div>
            <h1>{t('menu.team.heading')}</h1>
            <img src={arrowLeft} className="title" alt="Back" onClick={() => navigate('/menu')} />
        </div>
      
        <div className='menu-page-content'>
            <div className='menu-page-content-text'>
                <p>{t('menu.team.text1')}</p>
                <p>{t('menu.team.text2')}</p>
                <p>{t('menu.team.text3')}</p>
                <small>{t('menu.team.text_small')}</small>
            </div>

            <div className='menu-page-content-dr'>
                <div className='menu-page-content-dr-card'>
                    <img src={dr} className="dr" alt="Dr" loading="lazy" />
                    <p className='bold'>{t('menu.team.dr')}</p>
                    <p>{t('menu.team.dr2')}</p>
                    <small>{t('menu.team.dr3')}</small>
                    <small>{t('menu.team.dr4')}</small>
                </div>
            </div>
        </div>

        <hr className='pink-border'></hr>
    </div>
  );
}

export default TeamComponent;