import { useTranslation } from 'react-i18next';
import NavbarComponent from '../Navbar/Navbar';
import FooterDesktopComponent from '../FooterDesktop/FooterDesktop';

const AboutUsDesktopComponent = ({window}) => {
  const { t } = useTranslation('common');

  return (
    <div className="homepage-desktop-page">
        <NavbarComponent window={window}></NavbarComponent>
        <div className='homepage-desktop-page-content'>
            <h1>{t('menu.about_us.heading')}</h1>
            <h4>{t('menu.about_us.text0')}</h4>
            <h4>{t('menu.about_us.text1')}</h4>
            <h4>{t('menu.about_us.text2')}</h4>
            <h4>{t('menu.about_us.text3')}</h4>
            <h4>{t('menu.about_us.text4')}</h4>
            <h4>{t('menu.about_us.text5')}</h4>
        </div>
        <FooterDesktopComponent></FooterDesktopComponent>
    </div>
  );
}

export default AboutUsDesktopComponent;