import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common.json";
import common_nl from "./translations/nl/common.json";

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'nl',  
    resources: {
        'en': {
            common: common_en
        },
        'nl': {
            common: common_nl
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
);
