import { Trans, useTranslation } from 'react-i18next';
import NavbarComponent from '../Navbar/Navbar';
import FooterDesktopComponent from '../FooterDesktop/FooterDesktop';
import phone from '../../assets/images/Phone_website.png';
import love_to_hear_en from '../../assets/images/Love_to_hear_en.png';
import love_to_hear_nl from '../../assets/images/Love_to_hear_nl.png';
import email from '../../assets/images/Email.png';
import whatsapp from '../../assets/images/Whatsapp.png';

const ContactDesktopComponent = ({window}) => {
  const { t, i18n } = useTranslation('common');

  return (
    <div className="homepage-desktop-page">
        <NavbarComponent window={window}></NavbarComponent>
        <div className='homepage-desktop-page-content'>
            {
                i18n.language === 'en'
                ?
                <img src={love_to_hear_en} className='contact-image' alt="Love to hear from you" loading="lazy" />
                :
                <img src={love_to_hear_nl} className='contact-image' alt="Love to hear from you" loading="lazy" />
            }
            <div className='homepage-desktop-page-content-center contact'>
                <img src={phone} className='contact-phone' alt="Mobile website" />
                <div className='contact-main'>
                    <h3><Trans i18nKey="menu.contact.text1">{t('menu.contact.text1')}</Trans></h3>
                    <div>
                        <span className='contact-details'>
                            <img src={email} className='contact-icon' alt='Email' />
                            <h5>info@247dermatologist.com</h5>
                        </span>
                        <span className='contact-details'>
                            <img src={whatsapp} className='contact-icon' alt='Whatsapp' />
                            <h5>+297 569 65 31</h5>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <FooterDesktopComponent></FooterDesktopComponent>
    </div>
  );
}

export default ContactDesktopComponent;