import { Trans, useTranslation } from 'react-i18next';
import NavbarComponent from '../Navbar/Navbar';
import FooterDesktopComponent from '../FooterDesktop/FooterDesktop';
import qr_nl from '../../assets/images/Qr_nl.png';
import qr_en from '../../assets/images/Qr_en.png';

const StartTreatmentComponent = ({window}) => {
  const { t, i18n } = useTranslation('common');

  return (
    <div className="homepage-desktop-page">
        <NavbarComponent window={window}></NavbarComponent>
        <div className='homepage-desktop-page-content homepage-desktop-page-content-center'>
            {
                i18n.language === 'en'
                ?
                <img src={qr_en} alt='QR code' loading="lazy" />
                : 
                <img src={qr_nl} alt='QR code' loading="lazy"  />
            }
            <h2 className='bold'>
                <Trans i18nKey="menu.start.heading1">{t('menu.start.heading1')}</Trans>
            </h2>
            <h2>
                <Trans i18nKey="menu.privacy.heading2">{t('menu.start.heading2')}</Trans>
            </h2>
            <h4>
                <Trans i18nKey="menu.privacy.heading3">{t('menu.start.heading3')}</Trans>
            </h4>
        </div>
        <FooterDesktopComponent></FooterDesktopComponent>
    </div>
  );
}

export default StartTreatmentComponent;