import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/images/Logo_247dermatologist.png";

const SignupThankYou = () => {
  const { t, i18n } = useTranslation("common");
  const navigate = useNavigate();

  const backTopHome = ()=>{
    navigate("/",{ replace: true })
  }
  return (
    <div className="menu-page account">
      <div className="menu-page-header">
        <div className="languages">
          <h6
            className={i18n.language === "en" ? "active" : ""}
            onClick={() => i18n.changeLanguage("en")}
          >
            EN
          </h6>
          <h6
            className={i18n.language === "nl" ? "active" : ""}
            onClick={() => i18n.changeLanguage("nl")}
          >
            NL
          </h6>
        </div>
        {/* <h1>{t('account.banner')}</h1> */}
        {/* <img src={arrowLeft} className="title" alt="Back" onClick={() => navigate('/')} /> */}
      </div>
      <div className="menu-page-content">
        <div className="account-text">
          <img src={logo} className="logo" alt="247dermatologist.com"></img>

          <div class="d-flex justify-content-center align-items-center">
            <div class="p-2">
              <div class="mb-4 text-center">
                <FontAwesomeIcon icon={faCheckCircle} size="4x" color="green" />
              </div>
              <div class="text-center">
                <h1>{t('account.thankyou.thankGreet')}</h1>
                <div style={{fontSize:16, marginTop:10}}>
                {t('account.thankyou.thanksMessage')}
                </div>
              </div>
            </div>
          </div>
          <div className="account-button">
            <Button className="account-button-next" onClick={backTopHome}>
              {t('account.thankyou.btnBackToHome')}
            </Button>
          </div>
        </div>
      </div>

  
    </div>
  );
};

export default SignupThankYou;
