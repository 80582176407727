import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import arrowLeft from '../../assets/images/Arrow_left.png';
import logo from '../../assets/images/Logo_247dermatologist.png';
import bublCloudLogo from '../../assets/images/Bubl_Cloud_logo.png';

const AccountDermatologistPage = () => {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();
  const [sending, setSending] = useState(false);
  const [sendingError, setSendingError] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  const isValidEmail = (email) => {
    return /^[^\s@+]+@[^\s@+]+\.[^\s@+]+$/.test(email);
  }

  const handleChange = event => {
    if (!isValidEmail(event.target.value)) {
        setError(true);
    } else {
        setError(false);
    }

    setEmail(event.target.value);
  };

  const onClickNext = () => {
    setSending(true);
    const endpointUrl = 'https://api.bubl.cloud/247dermatologist/api/v1/signin/signupdermatologist';
    const newRequest = { email: email.trim(), locale: i18n.language === 'nl' ? 'nl-NL' : 'en-US'};
    const config = {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(newRequest)
    };

    return window
        .fetch(endpointUrl, config)
        .then(async (response) => {
            let data ;
            try {
                data = await response.json();
            } catch(e) {
            //
            }
            if (response.ok) {
                setSending(false);
                window.location.replace(data.bublDomain);
                return { data, response };
            } else {
                setSending(false);
                setSendingError(true);
                return Promise.reject({ response: response, data: data });
            }
        });
  }

  return (
    <div className='menu-page account'>
        <div className='menu-page-header'>
            <div className="languages">
                <h6 className={i18n.language === 'en' ? 'active' : ''} onClick={() => i18n.changeLanguage('en')}>EN</h6>
                <h6 className={i18n.language === 'nl' ? 'active' : ''} onClick={() => i18n.changeLanguage('nl')}>NL</h6>
            </div>
            <h1>{t('account.banner')}</h1>
            <img src={arrowLeft} className="title" alt="Back" onClick={() => navigate('/')} />
        </div>
    
        <div className='menu-page-content'>
            <div className='account-text'>
                <img src={logo} className="logo" alt="247dermatologist.com"></img>
                <div>
                    <p>{t('account.text1')}</p>
                    <p><Trans i18nKey="account.text_dermatologist2">{t('account.text_dermatologist2')}</Trans></p>
                </div>
                <div>
                    <input type="email" onChange={handleChange} value={email} placeholder={t('account.email')} />
                    {error && <small style={{color: '#ff5f90'}}>{t('account.email_error')}</small>}
                    <div>
                        <small className='bubl'>
                            Powered by
                            <img src={bublCloudLogo} alt='Bubl cloud' />
                        </small>
                        <small>{t('account.text3')}</small>
                    </div>
                </div>
                <div className='account-button'>
                    <Button
                        className="account-button-next"
                        onClick={onClickNext}
                        disabled={!isValidEmail(email) || sending}
                    >
                        <span className="account-button-next-text">{t('button.continue')}</span>
                        <FontAwesomeIcon icon={ faChevronRight } className="account-button-next-icon" />
                    </Button>
                </div>
                <div className='account-button'>
                  {sending ? <Spinner animation="border" variant="dark" /> : null}
                  {sendingError ? <small style={{color: '#ff5f90'}}>{t('account.error')}</small> : null}
                </div>
            </div>
        </div>

        <hr className='pink-border'></hr>
    </div>
  );
}

export default AccountDermatologistPage;