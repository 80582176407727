import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AccountPage from './components/AccountPage/AccountPage';
import './App.scss';
import HomePage from './components/HomePage/HomePage';
import HomePageDesktop from './components/HomePageDesktop/HomePageDesktop';
import AccountDermatologistPage from './components/AccountDermatologistPage/AccountDermatologistPage';
import MenuComponent from './components/Menu/Menu';
import TeamComponent from './components/Team/Team';
import AboutUsComponent from './components/AboutUs/AboutUs';
import PrivacyComponent from './components/Privacy/Privacy';
import ContactComponent from './components/Contact/Contact';
import AboutUsDesktopComponent from './components/AboutUsDesktop/AboutUsDesktop';
import StartTreatmentComponent from './components/StartTreatment/StartTreatment';
import ContactDesktopComponent from './components/ContactDesktop/ContactDesktop';
import FaqComponent from './components/FAQ/Faq';
import SignupThankYou from './components/SignupThankYou';

function App() {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path='/'
            element={
              width < 768 
              ?
              <HomePage window={window} />
              :
              <HomePageDesktop window={window} />
            }
          >
          </Route>
          <Route
            exact
            path='/start'
            element={<StartTreatmentComponent window={window} />}
          >
          </Route>
          <Route
            exact
            path='/menu'
            element={<MenuComponent />}
          >
          </Route>
          <Route
            exact
            path='/team'
            element={<TeamComponent />}
          >
          </Route>
          <Route
            exact
            path='/faq'
            element={<FaqComponent />}
          >
          </Route>
          <Route
            exact
            path='/contact'
            element={
              width < 768
              ?
              <ContactComponent />
              :
              <ContactDesktopComponent window={window} />
            }
          >
          </Route>
          <Route
            exact
            path='/about'
            element={
              width < 768
              ?
              <AboutUsComponent />
              :
              <AboutUsDesktopComponent window={window} />
            }
          >
          </Route>
          <Route
            exact
            path='/privacy'
            element={<PrivacyComponent />}
          >
          </Route>
          <Route
            exact
            path='/account'
            element={<AccountPage window={window} />}
          >
          </Route>
          <Route
            exact
            path='/thankyou'
            element={<SignupThankYou window={window} />}
          />
          <Route
            exact
            path='/dermatologist'
            element={<AccountDermatologistPage window={window} />}
          >
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
