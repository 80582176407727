import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import arrowLeft from '../../assets/images/Arrow_left.png';
import faq_1 from '../../assets/images/Faq_1.jpg';
import faq_2 from '../../assets/images/Faq_2.png';
import next from '../../assets/images/Next_arrow.png';
import check from '../../assets/images/Check_pink.svg';

const FaqComponent = () => {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const showQuestions = () => {
    setShow(!show);
  }

  return (
    <div className='menu-page'>
        <div className='menu-page-header'>
            <div className="languages">
                <h6 className={i18n.language === 'en' ? 'active' : ''} onClick={() => i18n.changeLanguage('en')}>EN</h6>
                <h6 className={i18n.language === 'nl' ? 'active' : ''} onClick={() => i18n.changeLanguage('nl')}>NL</h6>
            </div>
            <h1>FAQ</h1>
            <img src={arrowLeft} className="title" alt="Back" onClick={() => navigate('/menu')} />
        </div>

        {!show ?
            <div className='menu-page-content'>
                <div className='menu-page-content-text'>
                    <img src={faq_1} className='faq-image' alt="Q&A" loading="lazy" />
                    <p className='bold'>{t('menu.faq.questions')}</p>
                    <small>{t('menu.faq.text1')}</small>
                    <small>{t('menu.faq.text2')}</small>
                    <small>{t('menu.faq.text3')}</small>
                </div>
                <div className='menu-page-content-next'>
                    <img src={faq_2} alt="Q&A" loading="lazy"  />
                    <img src={next} alt="Next" onClick={showQuestions} />
                </div>
            </div>
            :
            <div className='menu-page-content'>
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h5>{t('menu.faq.question.1_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy"  />
                            <small>{t('menu.faq.question.1_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h5>{t('menu.faq.question.2_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy"  />
                            <small>{t('menu.faq.question.2_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h5>{t('menu.faq.question.3_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.3_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><h5>{t('menu.faq.question.4_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.4_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><h5>{t('menu.faq.question.5_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.5_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header><h5>{t('menu.faq.question.6_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.6_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header><h5>{t('menu.faq.question.7_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.7_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header><h5>{t('menu.faq.question.8_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.8_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header><h5>{t('menu.faq.question.9_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.9_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header><h5>{t('menu.faq.question.10_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.10_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header><h5>{t('menu.faq.question.11_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.11_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                        <Accordion.Header><h5>{t('menu.faq.question.12_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.12_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="12">
                        <Accordion.Header><h5>{t('menu.faq.question.13_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.13_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="13">
                        <Accordion.Header><h5>{t('menu.faq.question.14_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.14_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="14">
                        <Accordion.Header><h5>{t('menu.faq.question.15_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.15_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="15">
                        <Accordion.Header><h5>{t('menu.faq.question.16_header')}</h5></Accordion.Header>
                        <Accordion.Body>
                            <img src={check} alt='checked' loading="lazy" />
                            <small>{t('menu.faq.question.16_answer')}</small>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        }


        <hr className='pink-border'></hr>
    </div>
  );
}

export default FaqComponent;