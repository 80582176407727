import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import arrowLeft from '../../assets/images/Arrow_left.png';
import pdf from '../../assets/files/EN_Privacy_Policy _247dermatologist.pdf';

const PrivacyComponent = () => {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <div className='menu-page'>
        <div className='menu-page-header'>
            <div className="languages">
                <h6 className={i18n.language === 'en' ? 'active' : ''} onClick={() => i18n.changeLanguage('en')}>EN</h6>
                <h6 className={i18n.language === 'nl' ? 'active' : ''} onClick={() => i18n.changeLanguage('nl')}>NL</h6>
            </div>
            <h1>{t('menu.privacy.heading')}</h1>
            <img src={arrowLeft} className="title" alt="Back" onClick={() => navigate('/menu')} />
        </div>
      
        <div className='menu-page-content'>
            <div className='menu-page-content-text'>
                <p className='bold uppercase'>{t('menu.privacy.heading')}</p>
                <small>{t('menu.privacy.text1')}</small>
                <br></br>
                <small>
                    {t('menu.privacy.text2')}
                    &nbsp;
                    <a href={pdf} target='_blank' download={true}>
                        <Trans i18nKey="menu.privacy.link">
                            {t('menu.privacy.link')}
                        </Trans>
                    </a>
                </small>
            </div>
        </div>

        <hr className='pink-border'></hr>
    </div>
  );
}

export default PrivacyComponent;