import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import arrowLeft from '../../assets/images/Arrow_left.png';
import Button from 'react-bootstrap/esm/Button';
import linkedin from '../../assets/images/Linkedin.svg';
import instagram from '../../assets/images/Instagram.svg';
import twitter from '../../assets/images/Twitter.svg';

const MenuComponent = () => {
  const { t, i18n } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <div className='menu'>
        <Navbar>
            <div className="navigation-menu">
                <div className="navigation-menu-title">
                    <img src={arrowLeft} className="title" alt="Back" onClick={() => navigate('/')} />
                    <h1>Menu</h1>
                    <div className="languages menu-languages">
                        <h6 className={i18n.language === 'en' ? 'active' : ''} onClick={() => i18n.changeLanguage('en')}>EN</h6>
                        <h6 className={i18n.language === 'nl' ? 'active' : ''} onClick={() => i18n.changeLanguage('nl')}>NL</h6>
                    </div>
                </div>
            </div>
        </Navbar>
        <div className='menu-content'>
            <div className='menu-content-social'>
                {/* <Button className="start-button">
                    <FontAwesomeIcon className="icon" icon={ faShareAlt } onClick={() => navigate('/menu')} />
                    <span className="start-button-text" onClick={() => navigate('/account')}>{t('button.share')}</span>
                </Button> */}

                <div className='menu-content-social-links'>
                    <a href='https://www.linkedin.com/company/247dermatologist/' target="_blank" rel='noreferrer'><img src={linkedin} alt='LinkedIn' /></a>
                    <a href='https://www.instagram.com/247dermatologist/' target="_blank" rel='noreferrer'><img src={instagram} alt='Instagram' /></a>
                    <a href='https://twitter.com/247derm' target="_blank" rel='noreferrer'><img src={twitter} alt='Twitter' /></a> 
                </div>
            </div>

            <div className='menu-content-options'>
                <ul>
                    <li onClick={() => navigate('/team')}>
                        <h2>{t('menu.team.heading')}</h2>
                        <img src={arrowLeft} className="title" alt="Team" />
                    </li>
                    <li onClick={() => navigate('/faq')}>
                        <h2>FAQ</h2>
                        <img src={arrowLeft} className="title" alt="FAQ" />
                    </li>
                    <li onClick={() => navigate('/contact')}>
                        <h2>{t('menu.contact.heading')}</h2>
                        <img src={arrowLeft} className="title" alt="Contact"  />
                    </li>
                    <li onClick={() => navigate('/about')}>
                        <h2>{t('menu.about_us.heading')}</h2>
                        <img src={arrowLeft} className="title" alt="About us" />
                    </li>
                    <li onClick={() => navigate('/privacy')}>
                        <h2>{t('menu.privacy.heading')}</h2>
                        <img src={arrowLeft} className="title" alt="Privacy policy" />
                    </li>
                </ul>
            </div>

            <div className='menu-content-button'>
                <Button className="start-button">
                    <span className="start-button-text" onClick={() => navigate('/account')}>{t('button.getStarted')}</span>
                </Button>
                <small className="homepage-start-price">{t('small.price')}</small>
            </div>
        </div>
    </div>
  );
}

export default MenuComponent;