import { useTranslation } from 'react-i18next';
import linkedin from '../../assets/images/Linkedin.svg';
import instagram from '../../assets/images/Instagram.svg';
import twitter from '../../assets/images/Twitter.svg';
import logo from '../../assets/images/Logo.svg';

const FooterDesktopComponent = () => {
  const { t } = useTranslation('common');

  return (
    <footer>
        <img src={logo} className="footer-logo" alt="247dermatologist.com"></img>
        <div className='footer-links'>
            <small className='bold'>{t('footer.follow_us')}</small>
            <div>
                <div className='menu-content-social-links'>
                    <a href='https://www.linkedin.com/company/247dermatologist/' target="_blank" rel='noreferrer'><img src={linkedin} alt='LinkedIn' /></a>
                    <a href='https://www.instagram.com/247dermatologist/' target="_blank" rel='noreferrer'><img src={instagram} alt='Instagram' /></a>
                    <a href='https://twitter.com/247derm' target="_blank" rel='noreferrer'><img src={twitter} alt='Twitter' /></a> 
                </div>
                <small>&copy; 2023 247dermatologist BV</small>
            </div>
        </div>
    </footer>
  );
}

export default FooterDesktopComponent;