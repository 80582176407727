import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import NavbarComponent from '../Navbar/Navbar';
import homepage_en from '../../assets/images/Homepage_1_en_m.png'
import homepage_nl from '../../assets/images/Homepage_1_nl_m.png'
import people from '../../assets/images/People_247.jpg'
import peopleNl from '../../assets/images/People_247_nl.jpg'
import phone from '../../assets/images/Phone.jpg';
import review1 from '../../assets/images/iPad_review_1.png';
import review2 from '../../assets/images/iPad_review_2.png';
import review3 from '../../assets/images/iPad_review_3.png';
import review1en from '../../assets/images/iPad_review_1_en.png';
import review2en from '../../assets/images/iPad_review_2_en.png';
import review3en from '../../assets/images/iPad_review_3_en.png';
import medicalTeam1 from '../../assets/images/Medical_team_1.jpg';
import medicalTeam2 from '../../assets/images/Medical_team_2.jpg';
import medicalTeam3 from '../../assets/images/Medical_team_3.jpg';

const HomePage = ({ window }) => {
  const [index, setIndex] = useState(0);
  const { i18n, t } = useTranslation('common');
  const navigate = useNavigate();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="homepage">
        <NavbarComponent window={window}></NavbarComponent>
        <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
            <Carousel.Item>
                <div className="carousel-item-1">
                    {
                        i18n.language === 'en'
                        ?
                        <img src={homepage_en} alt='Homepage' />
                        :
                        <img src={homepage_nl} alt='Homepage' />
                    }
                </div>
            </Carousel.Item>
            <Carousel.Item>
                {
                    i18n.language === 'en'
                    ?
                    <img src={people} className="people" alt="247dermatologist.com"></img>
                    :
                    <img src={peopleNl} className="people" alt="247dermatologist.com"></img>
                }
                <div className="carousel-item-2-text">
                    <h2>{t('heading.h2.2_2')}</h2>
                    <p>{t('text.1')}</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="carousel-item-3-image">
                    <img src={phone} className="consult-example" alt="Consult example"></img>
                </div>

                <div className="carousel-item-3-text">
                    <h2>{t('heading.h2.3')}</h2>
                    <p>{t('text.2')}</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="carousel-item-image carousel-item-medical-team-image">
                    <Carousel interval={5000}>
                        <Carousel.Item>
                            <img src={medicalTeam1} className="review-image" alt="Medical team"></img>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={medicalTeam2} className="review-image" alt="Medical team"></img>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={medicalTeam3} className="review-image" alt="Medical team"></img>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="carousel-item-3-text">
                    <h2>{t('heading.h2.4')}</h2>
                    <p>{t('text.3')}</p>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="carousel-item-image">
                    <h2>{t('heading.h2.5_1')}</h2>
                    {
                        i18n.language === 'en'
                        ?
                        <Carousel interval={5000}>
                            <Carousel.Item>
                                <img src={review1en} className="review-image" alt="review"></img>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={review2en} className="review-image" alt="review"></img>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={review3en} className="review-image" alt="review"></img>
                            </Carousel.Item>
                        </Carousel>
                        :
                        <Carousel interval={5000}>
                            <Carousel.Item>
                                <img src={review1} className="review-image" alt="review"></img>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={review2} className="review-image" alt="review"></img>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img src={review3} className="review-image" alt="review"></img>
                            </Carousel.Item>
                        </Carousel>
                    }

                </div>

                <div className="carousel-item-3-text">
                    <h2>{t('heading.h2.5_2')}</h2>
                    <p>{t('text.5')}</p>
                </div>
            </Carousel.Item>
        </Carousel>
        <Button className="start-button">
            <span className="start-button-text" onClick={() => navigate('/account')}>{t('button.getStarted')}</span>
        </Button>
        <small className="homepage-start-price">{t('small.price')}</small>
    </div>
  );
}

export default HomePage;