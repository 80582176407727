import { Trans, useTranslation } from 'react-i18next';
import NavbarComponent from '../Navbar/Navbar';
import FooterDesktopComponent from '../FooterDesktop/FooterDesktop';
import homepage1nl from '../../assets/images/Homepage_1_nl.jpg'
import homepage1en from '../../assets/images/Homepage_1_en.jpg'
import homepage3 from '../../assets/images/Homepage_3.jpg'
import homepage3nl from '../../assets/images/Homepage_3_nl.jpg'
import dr_quote_en from '../../assets/images/Dr_quote_en.png';
import dr_quote_nl from '../../assets/images/Dr_quote_nl.png';
import check from '../../assets/images/Check.png';
import scan_en from '../../assets/images/Scan_en.png';
import scan_nl from '../../assets/images/Scan_nl.png';
import review1 from '../../assets/images/iPad_review_1.png';
import review2 from '../../assets/images/iPad_review_2.png';
import review3 from '../../assets/images/iPad_review_3.png';
import review1en from '../../assets/images/iPad_review_1_en.png';
import review2en from '../../assets/images/iPad_review_2_en.png';
import review3en from '../../assets/images/iPad_review_3_en.png';
import contact_nl from '../../assets/images/contact_nl.png';
import contact_en from '../../assets/images/contact_en.png';

const HomePageDesktop = () => {
    const { t, i18n } = useTranslation('common');

    return (
        <div className="homepage-desktop">
            <NavbarComponent window={window}></NavbarComponent>
            <section className="homepage-desktop-image">
                {
                    i18n.language === 'en'
                    ?
                    <img src={homepage3} className="people" alt="247dermatologist.com" />
                    :
                    <img src={homepage3nl} className="people" alt="247dermatologist.com" />
                }
            </section>

            <section className='homepage-desktop-description'>
                <div className='homepage-desktop-description-row'>
                    <div className='homepage-desktop-description-row-element'>
                        <h3 className='pink'>{t('banner.heading')}</h3>
                        <h5 className='italic'>{t('banner.heading2')}</h5>
                        <h4>
                            <Trans i18nKey="banner.text1">{t('banner.text1')}</Trans>
                        </h4>
                        <h4>
                            <Trans i18nKey="banner.text1">{t('banner.text2')}</Trans>
                        </h4>
                        <h4>{t('banner.text3')}</h4>
                    </div>
                    {
                        i18n.language === 'en'
                        ?
                        <img src={dr_quote_en} alt='Doctor quote' />
                        :
                        <img src={dr_quote_nl} alt='Doctor quote' />
                    }
                </div>
                <div className='homepage-desktop-description-row reverse'>
                    {
                        i18n.language === 'en'
                        ?
                        <img src={scan_en} alt='QR code' />
                        :
                        <img src={scan_nl} alt='QR code' />
                    }
                    <div className='homepage-desktop-description-row-element left'>
                        <h3 className='pink left'><Trans i18nKey="banner.heading3">{t('banner.heading3')}</Trans></h3>
                        <ul>
                            <li>
                                <img src={check} alt='checked' />
                                <h4>{t('banner.list_item_1')}</h4>
                            </li>
                            <li>
                                <img src={check} alt='checked' />
                                <h4>{t('banner.list_item_2')}</h4>
                            </li>        
                            <li>
                                <img src={check} alt='checked' />
                                <h4>{t('banner.list_item_3')}</h4>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="homepage-desktop-review">
                <div className="homepage-desktop-review-content">
                    <h2>{t('heading.h2.5_1')}</h2>
                    {
                        i18n.language === 'en'
                        ?
                        <div className="review-images">
                            <img src={review1en} className="review-image" alt="review"></img>
                            <img src={review2en} className="review-image" alt="review"></img>
                            <img src={review3en} className="review-image" alt="review"></img>
                        </div>
                        :
                        <div className="review-images">
                            <img src={review1} className="review-image" alt="review"></img>
                            <img src={review2} className="review-image" alt="review"></img>
                            <img src={review3} className="review-image" alt="review"></img>
                        </div>
                    }
                </div>
            </section>
            <section className="homepage-desktop-contact">
                {
                    i18n.language === 'en'
                    ?
                    <img src={contact_en} className="contact-image" alt="Contact"></img>
                    :
                    <img src={contact_nl} className="contact-image" alt="Contact"></img>
                }
            </section>
            <section className="homepage-desktop-banner">
                {
                    i18n.language === 'en'
                    ?
                    <img src={homepage1en} className="banner-image" alt="247dermatologist.com" />
                    :
                    <img src={homepage1nl} className="banner-image" alt="247dermatologist.com" />
                }
            </section>
            <FooterDesktopComponent></FooterDesktopComponent>
        </div>
    );
}

export default HomePageDesktop;